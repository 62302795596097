<template>
  <div v-if="storageSpacesData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("StorageSpaces.data") }}</th>
          <th rowspan="2">{{ $t("general.showStatus") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(storageSpace, index) in storageSpacesData" :key="index">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table no-border"
              :src="
                fullPathFileFromServer(
                  storageSpace.storageSpaceImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(storageSpace.fullCode) }}</td>
          <td>
            {{ isDataExist(storageSpace.storageSpaceNameCurrent) }}
          </td>
          <td>
            {{
              isDataExist(storageSpace.storageSpaceShowStatusTypeNameCurrent)
            }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <router-link
                  :to="{
                    name: storageSpacesMediaRoute,
                    params: {
                      mainToken: storageSpace.userToken,
                      storageSpaceToken: storageSpace.storageSpaceToken,
                    },
                  }"
                  :title="$t('StorageSpaces.open')"
                >
                  <img src="@/assets/images/open-folder.svg" />
                </router-link>
              </li>
              <li>
                <button
                  @click="
                    setStorageSpaceData(storageSpace);
                    openBottomSheet('StorageSpaceInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setStorageSpaceData(storageSpace);
                    openBottomSheet('StorageSpaceQRCode');
                  "
                  :title="$t('QR.modelName')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeEdit">
                <button
                  @click="
                    setStorageSpaceData(storageSpace);
                    openBottomSheet('StorageSpaceUpdate');
                  "
                  :title="$t('edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeFinaleDelete">
                <button
                  v-b-modal.StorageSpaceDelete
                  :title="$t('delete')"
                  @click="setStorageSpaceData(storageSpace)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
              <li v-if="hasPrivilegeChangeActivationType">
                <button
                  v-b-modal.StorageSpaceChangeActivationType
                  :title="$t('changeActivationType')"
                  @click="setStorageSpaceData(storageSpace)"
                >
                  <img src="@/assets/images/changeActivationType.svg" />
                </button>
              </li>
              <li>
                <button
                  @click="
                    setStorageSpaceData(storageSpace);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasStorageSpaceEmployeeEdit,
  hasStorageSpaceEmployeeFinaleDelete,
  hasStorageSpaceEmployeeChangeActivationType,
  hasStorageSpacePlaceEdit,
  hasStorageSpacePlaceFinaleDelete,
  hasStorageSpacePlaceChangeActivationType,
} from "./../../../utils/privilegeHelper";
import FloatingMenu from "./../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  name: "StorageSpacesTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["storageSpacesData", "filterData", "defaultImg"],
  data() {
    return {
      modelName: this.$route.meta.modelName,
      storageSpacesMediaRoute: this.$route.meta.storageSpacesMediaRoute,
    };
  },
  methods: {
    setStorageSpaceData(storageSpace) {
      this.$emit("setStorageSpaceData", storageSpace);
    },
    isDataExist,
    fullPathFileFromServer,
  },
  computed: {
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeEdit());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceEdit());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeFinaleDelete());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceFinaleDelete());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(
            hasStorageSpaceEmployeeChangeActivationType()
          );
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceChangeActivationType());
          break;
        default:
          break;
      }
      return status;
    },
  },
};
</script>
