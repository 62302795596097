<template>
  <b-modal
    id="StorageSpaceChangeActivationType"
    scrollable
    centered
    hide-footer
  >
    <PreLoader v-if="isLoading" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/changeActivationType.svg" class="icon-lg" />
        {{ $t("changeActivationType") }}
      </h3>
    </template>

    <h4 class="text-center">
      (
      <span class="co-red">
        {{ storageSpace.storageSpaceNameCurrent }}
      </span>
      )
    </h4>

    <div class="row">
      <CustomSelectBox
        :className="'col-12'"
        :id="'activationTypeToken'"
        :value="storageSpace.activationTypeToken"
        :options="activationTypesTokensOptions"
        v-on:changeValue="storageSpace.activationTypeToken = $event"
        :title="$t('general.selectActivationType')"
        :imgName="'changeActivationType.svg'"
        :imgStatus="true"
      />
    </div>

    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="changeActivationType"
      >
        {{ $t("ok") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('StorageSpaceChangeActivationType')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../components/general/PreLoader.vue";
import CustomSelectBox from "./../../../components/general/CustomSelectBox.vue";
import { STATUS } from "./../../../utils/constants";
import { ACTIVATION_TYPE } from "./../../../utils/constantLists";
import { checkPrivilege, objectToFormData } from "./../../../utils/functions";
import { getDialogOfActivationTypes } from "./../../../utils/dialogsOfConstantsLists";
import StorageSpaceDelete from "./../../../models/storageSpaces/StorageSpaceDelete";
import apiStorageSpace from "./../../../api/storageSpace";
import generalMixin from "./../../../utils/generalMixin";
import {
  hasStorageSpaceEmployeeActive,
  hasStorageSpaceEmployeeArchive,
  hasStorageSpaceEmployeeBlocked,
  hasStorageSpacePlaceActive,
  hasStorageSpacePlaceArchive,
  hasStorageSpacePlaceBlocked,
} from "./../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomSelectBox,
  },
  data() {
    return {
      model: new StorageSpaceDelete(),
      activationTypesTokenOptions: getDialogOfActivationTypes(),
      modelName: this.$route.meta.modelName,
    };
  },
  props: ["storageSpace"],
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    activationTypesTokensOptions: function () {
      let arr = [];
      if (checkPrivilege(this.hasPrivilegeActive)) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Active
        );
        if (obj) arr.push(obj);
      }
      if (checkPrivilege(this.hasPrivilegeArchive)) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Archived
        );
        if (obj) arr.push(obj);
      }
      if (checkPrivilege(this.hasPrivilegeBlocked)) {
        let obj = this.activationTypesTokenOptions.find(
          (e) => e.value === ACTIVATION_TYPE.Blocked
        );
        if (obj) arr.push(obj);
      }
      return arr;
    },

    hasPrivilegeActive() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeActive());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceActive());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeArchive() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeArchive());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceArchive());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeBlocked() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeBlocked());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceBlocked());
          break;
        default:
          break;
      }
      return status;
    },
  },
  methods: {
    async changeActivationType() {
      this.isLoading = true;
      try {
        this.model.fillData({
          token: this.storageSpace.storageSpaceToken,
          activationTypeToken: this.storageSpace.activationTypeToken,
          storageSpaceTypeToken: this.storageSpace.storageSpaceTypeToken,
        });
        let formData = objectToFormData(this.model);
        const response = await apiStorageSpace.changeActivationType(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("StorageSpaceChangeActivationType");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  // async created() {},
};
</script>
