<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(storageSpace, index) in storageSpacesData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="storageSpace.storageSpaceNameCurrent"
      :description="storageSpace.fullCode"
      :imagePath="storageSpace.storageSpaceImagePath"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <router-link
          :to="{
            name: storageSpacesMediaRoute,
            params: {
              mainToken: storageSpace.userToken,
              storageSpaceToken: storageSpace.storageSpaceToken,
            },
          }"
          :title="$t('StorageSpaces.open')"
        >
          <img src="@/assets/images/open-folder.svg" />
        </router-link>
      </li>
      <li>
        <button
          :title="$t('info')"
          @click="
            setStorageSpaceData(storageSpace);
            openBottomSheet('StorageSpaceInfo');
          "
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setStorageSpaceData(storageSpace);
            openBottomSheet('StorageSpaceQRCode');
          "
          :title="$t('QR.modelName')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeEdit">
        <button
          @click="
            setStorageSpaceData(storageSpace);
            openBottomSheet('StorageSpaceUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeFinaleDelete">
        <button
          v-b-modal.StorageSpaceDelete
          :title="$t('delete')"
          @click="setStorageSpaceData(storageSpace)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeChangeActivationType">
        <button
          v-b-modal.StorageSpaceChangeActivationType
          :title="$t('changeActivationType')"
          @click="setStorageSpaceData(storageSpace)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setStorageSpaceData(storageSpace);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../utils/functions";
import {
  hasStorageSpaceEmployeeEdit,
  hasStorageSpaceEmployeeFinaleDelete,
  hasStorageSpaceEmployeeChangeActivationType,
  hasStorageSpacePlaceEdit,
  hasStorageSpacePlaceFinaleDelete,
  hasStorageSpacePlaceChangeActivationType,
} from "./../../../utils/privilegeHelper";
import CustomCard from "./../../../components/general/CustomCard.vue";
import generalMixin from "./../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["storageSpacesData", "filterData", "defaultImg"],
  data() {
    return {
      modelName: this.$route.meta.modelName,
      storageSpacesMediaRoute: this.$route.meta.storageSpacesMediaRoute,
    };
  },
  methods: {
    setStorageSpaceData(storageSpace) {
      this.$emit("setStorageSpaceData", storageSpace);
    },
    isDataExist,
    fullPathFileFromServer,
  },
  computed: {
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeEdit());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceEdit());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(hasStorageSpaceEmployeeFinaleDelete());
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceFinaleDelete());
          break;
        default:
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "storageSpacesEmployees":
          status = checkPrivilege(
            hasStorageSpaceEmployeeChangeActivationType()
          );
          break;
        case "storageSpacesPlaces":
          status = checkPrivilege(hasStorageSpacePlaceChangeActivationType());
          break;
        default:
          break;
      }
      return status;
    },
  },
};
</script>
